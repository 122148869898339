
























































import {Component, Prop, Vue} from "vue-property-decorator";
import {documentAccept} from "@/constants/FileConstants";
import FileMetaDTO from "@/dto/files/FileMetaDTO";
import FileUpload from "@/components/common/FileUpload.vue";
import {namespace} from "vuex-class";
import UploadBankStatementsRequest from "@/dto/UploadBankStatementsRequest";
import CompanyService from "@/services/CompanyService";
import Workspace from "@/dto/auth/Workspace";
import ErrorLocalePathUtils from "@/utils/ErrorLocalePathUtils";
import Workspaces from "@/state/Workspaces";

const AppModule = namespace("App");
const Auth = namespace("Auth");

@Component({
    components: {
        FileUpload
    }
})
export default class BankStatementsModal extends Vue{
    private message = "";

    private successful = false;

    private accept = documentAccept;

    private file: FileMetaDTO | null = null;

    private years = ["2023", "2022", "2021", "2020", "2019", "2018"];

    private request = new UploadBankStatementsRequest();

    @AppModule.Action
    private startLoading!: () => void;

    @AppModule.Action
    private stopLoading!: () => void;

    @Prop()
    private onSave!: (year: string) => void

    mounted() {
        this.request.companyId = Workspaces.getCurrent.id;
    }

    closeModal(){
        this.$modal.hideAll();
    }

    errorHandle(error: any) {
        this.successful = false;
        this.message = this.$t(ErrorLocalePathUtils.getPathForError(error)) as string;
        this.stopLoading()
    }

    save() {
        this.$validator.validateAll().then(
            isValid => {
                if(isValid){
                    this.startLoading();
                    this.request.businessTaxReturnMetaId = this.file?.id as number;
                    CompanyService.uploadBankStatements(this.request).then(
                        () => {
                            this.onSave(this.request.year as string);
                            this.stopLoading();
                            this.closeModal();
                        },
                        err => this.errorHandle(err)
                    )
                }
            }
        );
    }

    onFileLoaded(files: FileMetaDTO[]){
        if(!files.length){
            return;
        }
        this.file = files[0];
    }

    getMonth(period: number) {
        switch (period) {
            case 1:
                return this.$t("period.month.jan");
            case 2:
                return this.$t("period.month.feb");
            case 3:
                return this.$t("period.month.mar");
            case 4:
                return this.$t("period.month.apr");
            case 5:
                return this.$t("period.month.may");
            case 6:
                return this.$t("period.month.jun");
            case 7:
                return this.$t("period.month.jul");
            case 8:
                return this.$t("period.month.aug");
            case 9:
                return this.$t("period.month.sep");
            case 10:
                return this.$t("period.month.oct");
            case 11:
                return this.$t("period.month.nov");
            case 12:
                return this.$t("period.month.dev");
        }
    }

    handleLabel(): string {
        return (!this.file ? "Upload" : "Change") + " bank statement";
    }

    warningHandle() {
        this.successful = false;
        this.message = "The bank statement must be attached ";
    }

}
